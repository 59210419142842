import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { FaUser, FaBriefcase, FaLightbulb, FaHandshake, FaChevronDown } from 'react-icons/fa';
import Header from './Career/Header';
import Footer from './Footer';
import ModernWorkspace from './Images/download.jpg';
import CollaborativeAreas from './Images/download (1).jpg';
import RelaxationZones from './Images/download (2).jpg';
import MeetingRooms from './Images/OIP (3).jpeg';

const CareerPage = () => {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen flex flex-col font-sans text-gray-900 bg-gray-50"
    >
      <Header />
      <main className="flex-grow">
        <HeroSection />
        <WhoWeAre />
        <OurOffice />
        <OurBenefits />
        <OpenPositions />
      </main>
      <Footer />
    </motion.div>
  );
};

const HeroSection = () => {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="relative h-screen flex items-center justify-center overflow-hidden bg-gradient-to-r from-teal-800 to-teal-600"
    >
      <div className="absolute inset-0 z-0">
        <svg className="w-full h-full" viewBox="0 0 100 100" preserveAspectRatio="none">
          <defs>
            <radialGradient id="herogradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
              <stop offset="0%" stopColor="rgba(255,255,255,0.1)" />
              <stop offset="100%" stopColor="rgba(255,255,255,0)" />
            </radialGradient>
          </defs>
          <motion.circle 
            cx="50" 
            cy="50" 
            r="50" 
            fill="url(#herogradient)"
            initial={{ scale: 0 }}
            animate={{ scale: 2 }}
            transition={{ duration: 20, repeat: Infinity, repeatType: "reverse", ease: "easeInOut" }}
          />
        </svg>
      </div>
      <div className="relative z-10 text-center px-4">
        <motion.h1 
          className="text-5xl md:text-7xl font-extrabold text-white mb-6 leading-tight"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.8, ease: "easeOut" }}
        >
          Join Our Team
        </motion.h1>
        <motion.p 
          className="text-xl md:text-2xl text-teal-100 mb-8"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.7, duration: 0.8, ease: "easeOut" }}
        >
          Be part of something extraordinary
        </motion.p>
        <motion.button 
          className="bg-white text-teal-800 px-8 py-3 rounded-full font-semibold text-lg shadow-lg hover:bg-teal-100 transition duration-300"
          whileHover={{ scale: 1.05, backgroundColor: "#E6FFFA" }}
          whileTap={{ scale: 0.95 }}
        >
          Explore Opportunities
        </motion.button>
      </div>
      <motion.div
        className="absolute bottom-10 left-1/2 transform -translate-x-1/2"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, duration: 0.8, repeat: Infinity, repeatType: "reverse" }}
      >
        <FaChevronDown className="text-white text-4xl" />
      </motion.div>
    </motion.div>
  );
};

const WhoWeAre = () => {
  const infoItems = [
    {
      icon: <FaLightbulb className="text-4xl text-teal-600" />,
      title: "Innovative Mindset",
      description: "We foster creativity and encourage out-of-the-box thinking to drive innovation in everything we do."
    },
    {
      icon: <FaHandshake className="text-4xl text-teal-600" />,
      title: "Collaborative Spirit",
      description: "Our team thrives on cooperation, leveraging diverse skills to achieve common goals."
    },
    {
      icon: <FaUser className="text-4xl text-teal-600" />,
      title: "Continuous Growth",
      description: "We're committed to personal and professional development, always striving to be better than yesterday."
    }
  ];

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      className="py-20 bg-white"
    >
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center text-teal-800 mb-12"
          initial={{ y: -50 }}
          animate={{ y: 0 }}
          transition={{ type: "spring", stiffness: 100 }}
        >
          Who We Are
        </motion.h2>
        <div className="grid md:grid-cols-3 gap-8">
          {infoItems.map((item, index) => (
            <InfoItem key={index} {...item} index={index} />
          ))}
        </div>
      </div>
    </motion.div>
  );
};

const InfoItem = ({ icon, title, description, index }) => {
  return (
    <motion.div 
      className="bg-teal-50 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.2, duration: 0.5, ease: "easeOut" }}
      whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
    >
      <div className="flex items-center mb-4">
        {icon}
        <h3 className="text-xl font-semibold ml-4 text-teal-800">{title}</h3>
      </div>
      <p className="text-gray-700">{description}</p>
    </motion.div>
  );
};

const OurOffice = () => {
  const images = [
    { src: ModernWorkspace, title: "Modern Workspace" },
    { src: CollaborativeAreas, title: "Collaborative Areas" },
    { src: RelaxationZones, title: "Relaxation Zones" },
    { src: MeetingRooms, title: "Meeting Rooms" }
  ];

  return (
    <div className="py-20 bg-gray-100">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center text-teal-800 mb-12"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Our Office
        </motion.h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
          {images.map((image, index) => (
            <ImageCard key={index} {...image} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

const ImageCard = ({ src, title, index }) => {
  return (
    <motion.div
      className="relative overflow-hidden rounded-lg shadow-lg"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ delay: index * 0.2 }}
      whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
    >
      <img
        src={src}
        alt={title}
        className="w-full h-64 object-cover transition-transform duration-300 hover:scale-110"
      />
      <div className="absolute inset-0 bg-gradient-to-t from-teal-800 via-transparent to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300">
        <div className="absolute bottom-0 left-0 right-0 p-4">
          <h3 className="text-white text-xl font-semibold">{title}</h3>
        </div>
      </div>
    </motion.div>
  );
};

const OurBenefits = () => {
  const benefits = [
    {
      icon: <FaBriefcase className="text-3xl text-white" />,
      title: "Competitive Salary & Equity",
      description: "We offer top-tier compensation packages",
      gradient: "from-teal-600 to-teal-400"
    },
    {
      icon: <FaUser className="text-3xl text-white" />,
      title: "Health, Dental & Vision Insurance",
      description: "Comprehensive health plans for you and your family",
      gradient: "from-teal-500 to-teal-300"
    },
    {
      icon: <FaLightbulb className="text-3xl text-white" />,
      title: "Flexible Work Hours & Remote Work",
      description: "Work from anywhere, anytime",
      gradient: "from-teal-700 to-teal-500"
    }
  ];

  return (
    <div className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center text-teal-800 mb-12"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Our Benefits
        </motion.h2>
        <div className="grid md:grid-cols-3 gap-8">
          {benefits.map((benefit, index) => (
            <BenefitCard key={index} {...benefit} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

const BenefitCard = ({ icon, title, description, gradient, index }) => {
  return (
    <motion.div
      className={`bg-gradient-to-br ${gradient} text-white p-6 rounded-lg shadow-lg`}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.2, duration: 0.5 }}
      whileHover={{ scale: 1.05, rotate: 1, transition: { duration: 0.3 } }}
    >
      <div className="flex items-center mb-4">
        {icon}
        <h3 className="text-xl font-semibold ml-4">{title}</h3>
      </div>
      <p>{description}</p>
    </motion.div>
  );
};


const OpenPositions = () => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchJobs = async () => {
      const querySnapshot = await getDocs(collection(db, 'jobDescription'));
      const jobData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJobs(jobData);
      setFilteredJobs(jobData);
    };
    fetchJobs();
  }, []);

  useEffect(() => {
    const results = jobs.filter(job => 
      job.jobTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.location?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredJobs(results);
  }, [searchTerm, jobs]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="py-20 bg-gray-100">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center text-primary-800 mb-12"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Open Positions
        </motion.h2>
        <div className="mb-8">
          <input
            type="text"
            placeholder="Search jobs by title or location..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full p-2 border border-primary-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
          />
        </div>
        <motion.div 
          className="grid md:grid-cols-2 lg:grid-cols-3 gap-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <AnimatePresence>
            {filteredJobs.map((job, index) => (
              <JobCard key={job.id} job={job} index={index} />
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};

const JobCard = ({ job, index }) => {
  return (
    <motion.div 
      className="bg-white p-6 rounded-lg shadow-lg border border-primary-200 hover:shadow-xl transition-shadow duration-300"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ delay: index * 0.1 }}
    >
      <h3 className="text-xl font-bold text-primary-800 mb-3">{job.jobTitle || 'Untitled Position'}</h3>
      <p className="text-gray-700 mb-2">
        <strong>Experience:</strong> {job.experience != null ? `${job.experience} years` : 'Not specified'}
      </p>
      <p className="text-gray-700 mb-4">
        <strong>Location:</strong> {job.location || 'Not specified'}
      </p>
      <div className="flex justify-between items-center">
        <Link to={`/job/${job.id}`} className="text-primary-600 hover:text-primary-800 font-medium transition duration-300">
          View Details
        </Link>
        <Link to={`/apply/${job.id}`} className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 transition duration-300">
          Apply Now
        </Link>
      </div>
    </motion.div>
  );
};



export default CareerPage;