import React from 'react';
import { motion } from 'framer-motion';
import Header from './Header';
import Footer from '../Footer';

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  in: { opacity: 1, y: 0 },
  out: { opacity: 0, y: -20 }
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5
};

const ConsultingPage = () => {
  return (
    <>
      <Header />
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
        className="bg-gradient-to-b from-green-50 to-green-100 min-h-screen py-32 px-6 md:px-12"
      >
        <div className="max-w-4xl mx-auto">
          <motion.h1 
            className="text-5xl font-bold text-green-900 mb-6"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Consulting
          </motion.h1>
          <motion.p 
            className="text-xl text-green-800 mb-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            Our expert consultants provide strategic guidance to help you navigate the complex world of technology and drive your business forward.
          </motion.p>
          
          <motion.div 
            className="bg-white rounded-lg shadow-lg p-8 mb-12"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.6, duration: 0.8 }}
          >
            <h2 className="text-3xl font-semibold text-green-900 mb-6">Our Consulting Services</h2>
            <div className="grid md:grid-cols-2 gap-6">
              {[
                { title: "Technology Strategy", description: "Align your IT initiatives with your business objectives for maximum impact." },
                { title: "Digital Transformation", description: "Navigate the digital landscape and transform your business for the future." },
                { title: "Cybersecurity Consulting", description: "Protect your assets with comprehensive security strategies and best practices." },
                { title: "Agile & DevOps Consulting", description: "Optimize your development processes for faster, more efficient delivery." }
              ].map((service, index) => (
                <motion.div 
                  key={index}
                  className="bg-green-50 p-4 rounded-lg"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.8 + index * 0.1, duration: 0.5 }}
                >
                  <h3 className="text-xl font-semibold text-green-900 mb-2">{service.title}</h3>
                  <p className="text-green-800">{service.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
          
          <motion.div
            className="bg-green-800 text-white p-8 rounded-lg mb-12"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.4, duration: 0.8 }}
          >
            <h3 className="text-2xl font-semibold mb-4">The Value of Our Consulting</h3>
            <ul className="grid md:grid-cols-2 gap-4">
              {[
                "Industry-specific expertise",
                "Data-driven insights and recommendations",
                "Customized strategies for your unique challenges",
                "Implementation roadmaps and support",
                "Risk mitigation and compliance guidance",
                "Continuous improvement methodologies"
              ].map((feature, index) => (
                <motion.li 
                  key={index}
                  className="flex items-center"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1.6 + index * 0.1, duration: 0.5 }}
                >
                  <svg className="w-6 h-6 mr-2 text-green-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  {feature}
                </motion.li>
              ))}
            </ul>
          </motion.div>

          <motion.div
            className="bg-white rounded-lg shadow-lg p-8"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 2, duration: 0.8 }}
          >
            <h3 className="text-2xl font-semibold text-green-900 mb-4">Ready to Transform Your Business?</h3>
            <p className="text-green-800 mb-6">
              Our consultants are ready to help you tackle your most pressing challenges and seize new opportunities. Let's start a conversation about your business goals.
            </p>
            <motion.button
              className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Schedule a Free Consultation
            </motion.button>
          </motion.div>
        </div>
      </motion.div>
      <Footer />
    </>
  );
};

export default ConsultingPage;