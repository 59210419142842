// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import AboutPage from './Components/AboutPage';
// import ServicesPage from './Components/ServicesPage';
import TechnologiesPage from './Components/TechnologiesPage';
 import Career from './Components/Career'; import MorePage from './Components/MorePage';
import ContactPage from './Components/ContactUs';
import JobDetail from './Components/Career/JobDetail';
import JobApplication from './Components/Career/JobApplication';
import BlogPost from './Components/BlogPost';
import CustomServices from './Components/CustomServices';
import WebsiteDevelopmentPage from './Components/Customservices/WebsiteDevelopmentPage';
import AIProductsPage from './Components/Customservices/AIProductsPage';
import ConsultingPage from './Components/Customservices/ConsultingPage'; 
import ServicesComponent from './Components/Customservices/ServicesPage';
import SolutionsPage from './Components/Customservices/SolutionsPage';
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<CustomServices />} />
    
         <Route path="/technologies" element={<TechnologiesPage />} />
        
        <Route path="/services/Websitedevelopment" element={<WebsiteDevelopmentPage />} />
        <Route path="/services/ai-products" element={<AIProductsPage/>} />
        <Route path="/services/tech-consulting" element={<ConsultingPage/>} />
        <Route path="/services/it-services" element={<ServicesComponent/>} />
        <Route path="/services/tech-solutions" element={<SolutionsPage/>} />
        <Route path="/contact-us" element={<ContactPage />} /> 
        <Route path="/career" element={<Career />} />
        <Route path="/job/:id" element={<JobDetail />} />
        <Route path="/apply/:id" element={<JobApplication />} />
        <Route path="/blog/:id" element={<BlogPost/>}/>
        
        {/* Add other routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;
