import React from 'react';
import { motion } from 'framer-motion';
import { Code, Database, Cloud, Server, Cpu, Lock } from 'lucide-react';

const TechCard = ({ icon: Icon, title, description, index }) => {
  return (
    <motion.div
      className="p-6 rounded-lg bg-white shadow-lg hover:shadow-xl transition-all duration-300 border border-teal-200 overflow-hidden relative"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      whileHover={{ 
        scale: 1.05,
        borderColor: "#0F766E"
      }}
    >
      {/* hi */}
      <motion.div 
        className="absolute inset-0 bg-gradient-to-br from-teal-50 to-transparent opacity-0 transition-opacity duration-300"
        whileHover={{ opacity: 1 }}
      />
      <motion.div 
        className="mb-4 text-teal-700 relative z-10"
        whileHover={{ scale: 1.1, color: "#0F766E" }}
      >
        <Icon size={40} />
      </motion.div>
      <motion.h3 
        className="text-xl font-semibold mb-2 text-teal-900 relative z-10"
        whileHover={{ color: "#0F766E" }}
      >
        {title}
      </motion.h3>
      <motion.p 
        className="text-teal-600 relative z-10"
        whileHover={{ color: "#134E4A" }}
      >
        {description}
      </motion.p>
    </motion.div>
  );
};

const TechnologiesSection = () => {
  const technologies = [
    {
      icon: Code,
      title: "Innovative Web Solutions",
      description: "We craft cutting-edge web applications using React, Vue.js, and Next.js, ensuring optimal performance and user experience."
    },
    {
      icon: Database,
      title: "Data Management",
      description: "Our expertise in SQL and NoSQL databases enables efficient data storage, retrieval, and analysis for your business needs."
    },
    {
      icon: Cloud,
      title: "Cloud Integration",
      description: "We leverage AWS, Azure, and Google Cloud to build scalable, secure, and cost-effective cloud infrastructures."
    },
    {
      icon: Cpu,
      title: "AI & Machine Learning",
      description: "Our AI solutions, powered by TensorFlow and PyTorch, drive intelligent decision-making and process automation."
    },
    {
      icon: Server,
      title: "DevOps Excellence",
      description: "We implement robust CI/CD pipelines using Docker, Kubernetes, and Jenkins for seamless development and deployment."
    },
    {
      icon: Lock,
      title: "Cybersecurity",
      description: "Our advanced security measures protect your digital assets, ensuring data integrity and compliance with industry standards."
    }
  ];

  return (
    <section className="py-16 bg-white to-teal-100">
      <div className="container mx-auto px-4">
        <motion.div 
          className="text-center mb-16"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-4xl md:text-5xl font-bold text-teal-900 mb-4">
            Empowering <span className="text-teal-600">Innovation</span>
          </h2>
          <p className="text-xl text-teal-700 max-w-2xl mx-auto">
            Discover how Tenchtechtree's cutting-edge technologies drive digital transformation and business growth.
          </p>
        </motion.div>
        
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {technologies.map((tech, index) => (
            <TechCard key={index} {...tech} index={index} />
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default TechnologiesSection;