import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, X, ChevronDown, ChevronRight, Phone, Globe, Search } from 'lucide-react';
import logo from './Images/17268416088752m7he9od.png';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [mobileActiveDropdown, setMobileActiveDropdown] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const searchInputRef = useRef(null);
  const navigate = useNavigate();

  const links = [
    { name: 'Home', path: '/', keywords: ['home', 'main', 'landing'] },
    { name: 'About', path: '/about', keywords: ['about', 'company', 'info', 'information'] },
    { name: 'Services', path: '/services', keywords: ['services', 'offerings'] },
    { name: 'Technologies', path: '/technologies', keywords: ['technologies', 'tech stack', 'programming', 'languages'] },
    { name: 'Career', path: '/career', keywords: ['career', 'jobs', 'positions', 'openings', 'employment'] },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isSearchOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isSearchOpen]);

  const headerVariants = {
    hidden: { y: -100 },
    visible: { y: 0, transition: { type: 'spring', stiffness: 100, damping: 20 } },
  };

  const linkVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const searchVariants = {
    hidden: { opacity: 0, width: 0 },
    visible: { opacity: 1, width: '300px', transition: { duration: 0.3 } },
  };

  const resultsVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.2 } },
  };

  const toggleMobileDropdown = (linkName) => {
    setMobileActiveDropdown(mobileActiveDropdown === linkName ? null : linkName);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 1) {
      const results = links.flatMap(link => {
        const mainMatch = link.keywords.some(keyword => keyword.includes(query.toLowerCase()));
        const sublinkMatches = link.sublinks
          ? link.sublinks.filter(sublink => sublink.keywords.some(keyword => keyword.includes(query.toLowerCase())))
          : [];
        
        return mainMatch 
          ? [{ ...link, isMainLink: true }] 
          : sublinkMatches.map(sublink => ({ ...sublink, parentName: link.name }));
      });

      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchResults.length > 0) {
      const firstResult = searchResults[0];
      navigate(firstResult.path);
      setIsSearchOpen(false);
      setSearchQuery('');
      setSearchResults([]);
    }
  };

  const handleResultClick = (result) => {
    navigate(result.path);
    setIsSearchOpen(false);
    setSearchQuery('');
    setSearchResults([]);
  };
  const toggleMobileMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMenuOpen(false);
    setMobileActiveDropdown(null);
  };

  return (
    <motion.header 
      className={`fixed top-0 left-0 right-0 z-50 bg-white ${isScrolled ? 'shadow-lg' : ''}`}
      initial="hidden"
      animate="visible"
      variants={headerVariants}
    >
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center py-2">
          <Link to="/" className="flex items-center space-x-2">
            <motion.img 
              src={logo} 
              alt="Tranchetechtre" 
              className="h-16 w-auto"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            />
            <motion.span 
              className="text-2xl font-bold text-teal-800"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              Tranchetechtre
            </motion.span>
          </Link>

          <nav className="hidden lg:flex space-x-8">
            {links.map((link, index) => (
              <motion.div 
                key={index} 
                className="relative group"
                variants={linkVariants}
                initial="hidden"
                animate="visible"
                onMouseEnter={() => setActiveDropdown(link.name)}
                onMouseLeave={() => setActiveDropdown(null)}
              >
                <Link 
                  to={link.path}
                  className="text-teal-800 hover:text-teal-600 transition-colors duration-300 py-2 flex items-center"
                >
                  {link.name}
                  {link.sublinks && <ChevronDown className="inline-block ml-1 w-4 h-4" />}
                </Link>
                {link.sublinks && activeDropdown === link.name && (
                  <motion.div 
                    className="absolute left-0 mt-2 w-64 bg-white shadow-xl rounded-md overflow-hidden"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {link.sublinks.map((sublink, subIndex) => (
                      <Link
                        key={subIndex}
                        to={sublink.path}
                        className="block px-4 py-2 text-teal-800 hover:bg-teal-50 hover:text-teal-600 transition-colors duration-300"
                      >
                        {sublink.name}
                      </Link>
                    ))}
                  </motion.div>
                )}
              </motion.div>
            ))}
          </nav>

          <div className="hidden lg:flex items-center space-x-4">
            <AnimatePresence>
              {isSearchOpen && (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={searchVariants}
                  className="relative"
                >
                  <form onSubmit={handleSearchSubmit} className="relative">
                    <motion.input
                      ref={searchInputRef}
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="w-full bg-gray-100 text-teal-800 rounded-full py-2 px-4 pr-10 focus:outline-none focus:ring-2 focus:ring-teal-300 shadow-inner"
                    />
                    {/* <motion.button
                      type="submit"
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 text-teal-600 hover:text-teal-800"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <Search className="w-5 h-5" />
                    </motion.button> */}
                  </form>
                  <AnimatePresence>
                    {searchResults.length > 0 && (
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={resultsVariants}
                        className="absolute left-0 right-0 mt-2 bg-white shadow-xl rounded-md overflow-hidden max-h-60 overflow-y-auto"
                      >
                        {searchResults.map((result, index) => (
                          <motion.div
                            key={index}
                            className="px-4 py-2 hover:bg-teal-50 cursor-pointer"
                            onClick={() => handleResultClick(result)}
                            whileHover={{ backgroundColor: '#e6fffa' }}
                          >
                            <p className="text-teal-800 font-semibold">
                              {result.isMainLink ? result.name : `${result.parentName} - ${result.name}`}
                            </p>
                            <p className="text-teal-600 text-sm">{result.keywords.join(', ')}</p>
                          </motion.div>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              )}
            </AnimatePresence>
            
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-transparent text-teal-800 p-2 rounded-full hover:bg-teal-50 transition-colors duration-300"
              onClick={() => setIsSearchOpen(!isSearchOpen)}
            >
              <Search className="w-5 h-5" />
            </motion.button>
            
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-transparent text-teal-800 p-2 rounded-full hover:bg-teal-50 transition-colors duration-300"
            >
              <Globe className="w-5 h-5" />
            </motion.button>
            
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link
                to="/contact-us"
                className="bg-teal-600 text-white px-6 py-2 rounded-full hover:bg-teal-700 transition-colors duration-300 flex items-center shadow-md"
              >
                <Phone className="w-4 h-4 mr-2" />
                Contact Us
              </Link>
            </motion.div>
          </div>

          <motion.button
            className="lg:hidden text-teal-800 hover:text-teal-600 transition-colors duration-300"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <Menu className="w-6 h-6" />
          </motion.button>
        </div>
      </div>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div 
            className="lg:hidden fixed inset-0 bg-white z-50 overflow-y-auto"
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'tween', duration: 0.3 }}
          >
            {/* Mobile menu content (unchanged) */}
            <div className="container mx-auto px-4 py-6">
              <div className="flex justify-between items-center mb-8">
                <Link to="/" className="flex items-center space-x-2" onClick={closeMobileMenu}>
                  <img src={logo} alt="Tranchetechtre" className="h-12 w-auto" />
                  <span className="text-2xl font-bold text-teal-800">Tranchetechtre</span>
                </Link>
                <motion.button
                  className="text-teal-800 hover:text-teal-600 transition-colors duration-300"
                  onClick={closeMobileMenu}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <X className="w-6 h-6" />
                </motion.button>
              </div>
              <nav className="space-y-4">
                {links.map((link, index) => (
                  <motion.div 
                    key={index}
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <div className="flex items-center justify-between">
                      <Link
                        to={link.path}
                        className="block text-lg text-teal-800 hover:text-teal-600 transition-colors duration-300 py-2"
                        onClick={() => {
                          if (!link.sublinks) closeMobileMenu();
                        }}
                      >
                        {link.name}
                      </Link>
                      {link.sublinks && (
                        <button
                          onClick={() => toggleMobileDropdown(link.name)}
                          className="p-2 text-teal-800 hover:text-teal-600 transition-colors duration-300"
                        >
                          <ChevronRight className={`w-5 h-5 transform transition-transform duration-300 ${mobileActiveDropdown === link.name ? 'rotate-90' : ''}`} />
                        </button>
                      )}
                    </div>
                    {link.sublinks && mobileActiveDropdown === link.name && (
                      <motion.div 
                        className="pl-4 mt-2 space-y-2"
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        {link.sublinks.map((sublink, subIndex) => (
                          <Link
                            key={subIndex}
                            to={sublink.path}
                            className="block text-teal-700 hover:text-teal-500 transition-colors duration-300 py-1"
                            onClick={closeMobileMenu}
                          >
                            {sublink.name}
                          </Link>
                        ))}
                      </motion.div>
                    )}
                  </motion.div>
                ))}
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: links.length * 0.1 }}
                >
                  <Link
                    to="/contact-us"
                    className="block bg-teal-600 text-white px-6 py-2 rounded-full hover:bg-teal-700 transition-colors duration-300 text-center mt-4"
                    onClick={closeMobileMenu}
                  >
                    Contact Us
                  </Link>
                </motion.div>
              </nav>
            </div>
            
          </motion.div>
        )}
      </AnimatePresence>
      
    </motion.header>
  );
};

export default Header;