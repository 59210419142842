import React from 'react';
import { motion } from 'framer-motion';
import Header from './Header';
import Footer from '../Footer';

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  in: { opacity: 1, y: 0 },
  out: { opacity: 0, y: -20 }
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5
};

const SolutionsPage = () => {
  return (
    <>
      <Header />
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
        className="bg-gradient-to-b from-blue-50 to-blue-100 min-h-screen py-32 px-6 md:px-12"
      >
        <div className="max-w-4xl mx-auto">
          <motion.h1 
            className="text-5xl font-bold text-blue-900 mb-6"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Solutions
          </motion.h1>
          <motion.p 
            className="text-xl text-blue-800 mb-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            We offer comprehensive solutions tailored to your business needs. Our expertise spans across various industries, providing innovative and efficient answers to complex challenges.
          </motion.p>
          
          <motion.div 
            className="bg-white rounded-lg shadow-lg p-8 mb-12"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.6, duration: 0.8 }}
          >
            <h2 className="text-3xl font-semibold text-blue-900 mb-6">Our Solution Categories</h2>
            <div className="grid md:grid-cols-2 gap-6">
              {[
                { title: "Enterprise Solutions", description: "Streamline your business processes with our comprehensive enterprise software solutions." },
                { title: "Cloud Solutions", description: "Leverage the power of cloud computing for scalability, flexibility, and cost-efficiency." },
                { title: "Data Analytics Solutions", description: "Transform your data into actionable insights with our advanced analytics tools." },
                { title: "Security Solutions", description: "Protect your digital assets with our state-of-the-art cybersecurity solutions." }
              ].map((solution, index) => (
                <motion.div 
                  key={index}
                  className="bg-blue-50 p-4 rounded-lg"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.8 + index * 0.1, duration: 0.5 }}
                >
                  <h3 className="text-xl font-semibold text-blue-900 mb-2">{solution.title}</h3>
                  <p className="text-blue-800">{solution.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
          
          <motion.div
            className="bg-blue-800 text-white p-8 rounded-lg"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.4, duration: 0.8 }}
          >
            <h3 className="text-2xl font-semibold mb-4">Why Choose Our Solutions?</h3>
            <ul className="grid md:grid-cols-2 gap-4">
              {[
                "Customized to your specific needs",
                "Scalable and future-proof",
                "Integrated with latest technologies",
                "Cost-effective implementation",
                "Ongoing support and maintenance",
                "Continuous innovation and updates"
              ].map((benefit, index) => (
                <motion.li 
                  key={index}
                  className="flex items-center"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1.6 + index * 0.1, duration: 0.5 }}
                >
                  <svg className="w-6 h-6 mr-2 text-blue-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  {benefit}
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </div>
      </motion.div>
      <Footer />
    </>
  );
};

export default SolutionsPage;