import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import {
  Globe,
  robo,
  Puzzle,
  Settings,
  Users
} from 'lucide-react';
import { BsRobot } from 'react-icons/bs';

const HeroSection = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const heroTexts = [
    "Innovate with AI-Powered Solutions",
    "Scale with Cloud-Native Architecture",
    "Secure with Blockchain Technology",
    "Transform with Digital Innovation"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % heroTexts.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const serviceIcons = [
    { icon: Globe, label: "Website" },
    { icon: BsRobot, label: "AI Product Based" },
    { icon: Puzzle, label: "Solutions" },
    { icon: Settings, label: "Services" },
    { icon: Users, label: "Consulting" }
  ];

  const handleGetStarted = () => {
    const featuresSection = document.getElementById('features');
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="relative min-h-screen bg-gradient-to-br from-gray-900 via-blue-900 to-teal-900 overflow-hidden flex items-center justify-center px-2 md:px-1 py-6">
      {/* Background Video */}
      <div className="absolute inset-0 overflow-hidden">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="w-full h-full object-cover opacity-30"
        >
          <source src="/tech-background.mp4" type="video/mp4" />
        </video>
      </div>

      {/* Animated Particles */}
      <div className="absolute inset-0 overflow-hidden">
        <div id="particles-js" className="w-full h-full"></div>
      </div>

      <motion.div
        className="relative z-10 text-center max-w-6xl mx-auto"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div variants={itemVariants} className="mb-8">
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold text-white mb-4">
            Tench<span className="text-teal-400">techtre</span>
          </h1>
        </motion.div>

        <AnimatePresence mode="wait">
          <motion.h2
            key={currentTextIndex}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="text-2xl sm:text-3xl md:text-4xl text-gray-200 mb-8 font-light px-2"
          >
            {heroTexts[currentTextIndex]}
          </motion.h2>
        </AnimatePresence>

        <motion.p
          variants={itemVariants}
          className="text-base sm:text-lg md:text-xl text-gray-300 mb-12 max-w-3xl mx-auto px-4"
        >
          Empowering businesses with state-of-the-art technology solutions to thrive in the digital era. Our expertise spans AI, cloud computing, blockchain, and more.
        </motion.p>

        <motion.div
          variants={itemVariants}
          className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 mb-16"
        >
          <motion.button
            onClick={handleGetStarted}
            whileHover={{ scale: 1.05, backgroundColor: '#ffffff', color: '#0D9488' }}
            whileTap={{ scale: 0.95 }}
            className="bg-teal-500 text-white px-6 py-3 rounded-full font-semibold flex items-center justify-center transition-colors duration-300"
          >
            Get Started
            <ArrowRight className="ml-2 w-5 h-5" />
          </motion.button>
          <motion.a
            href="/learn-more"
            whileHover={{ scale: 1.05, backgroundColor: 'rgba(255,255,255,0.1)' }}
            whileTap={{ scale: 0.95 }}
            className="border-2 border-teal-400 text-teal-400 px-6 py-3 rounded-full font-semibold transition-colors duration-300 hover:bg-teal-400 hover:text-white"
          >
            Learn More
          </motion.a>
        </motion.div>

        <motion.div 
          variants={itemVariants}
          className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-6 md:gap-8 max-w-4xl mx-auto px-4"
        >
          {serviceIcons.map((item, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center text-teal-400"
              whileHover={{ scale: 1.1, color: '#ffffff' }}
            >
              <item.icon size={40} />
              <span className="mt-2 text-xs sm:text-sm">{item.label}</span>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>

      {/* Scroll Indicator */}
      <motion.div
        className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, duration: 0.5, repeat: Infinity, repeatType: 'reverse' }}
      >
        <div className="w-6 h-10 border-2 border-white rounded-full p-1">
          <motion.div
            className="w-2 h-2 bg-white rounded-full mx-auto"
            animate={{ y: [0, 24, 0] }}
            transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}
          />
        </div>
      </motion.div>
    </section>
  );
};

export default HeroSection;