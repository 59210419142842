import React from 'react';
import { motion } from 'framer-motion';
import { Target, Laptop, Users, AlertCircle } from 'lucide-react';

const Feature = ({ title, description, icon: Icon }) => (
  <motion.div
    className="bg-white rounded-lg shadow-lg p-6 border-l-4 border-teal-700 transform transition-all duration-300 hover:scale-105 hover:shadow-xl"
    whileHover={{ y: -5 }}
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <div className="flex items-center mb-4">
      <div className="p-3 rounded-full bg-teal-700 mr-4">
        {Icon && <Icon size={24} className="text-white" />}
      </div>
      <h3 className="text-xl font-semibold text-teal-900">{title}</h3>
    </div>
    <p className="text-gray-700">{description}</p>
  </motion.div>
);

const Features = () => {
  const features = [
    {
      title: "Our Mission",
      description: "At Tecknotre, our mission is to empower businesses by delivering innovative IT solutions. We are committed to excellence and dedicated to helping our clients achieve their goals through cutting-edge technology.",
      icon: Target,
    },
    {
      title: "Our Expertise",
      description: "We specialize in website development, AI product-based solutions, comprehensive IT services, and consulting. Our team leverages the latest technologies to provide tailored solutions that meet your unique business needs.",
      icon: Laptop,
    },
    {
      title: "Our Clients",
      description: "We have partnered with a diverse range of clients, from startups to established enterprises across various industries such as healthcare, finance, education, and more. Our client-centric approach ensures we deliver value-driven solutions.",
      icon: Users,
    },
    {
      title: "More",
      description: "Explore additional information about our services, team, and success stories to understand how Tecknotre can help your business thrive in the digital landscape.",
      icon: AlertCircle,
    },
  ];

  return (
    <div className="bg-gradient-to-br from-teal-50 to-blue-50 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <motion.div 
          className="text-center mb-12"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-3xl font-extrabold text-teal-900 mb-2">Our Features</h2>
          <div className="w-24 h-1 bg-teal-700 mx-auto"></div>
        </motion.div>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
          {features.map((feature, index) => (
            <Feature key={index} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;