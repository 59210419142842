import React from 'react';
import { motion } from 'framer-motion';
import { Cpu, Cloud, Users, Zap } from 'lucide-react';

const Feature = ({ title, description, icon: Icon, delay }) => (
  <motion.div
    className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay }}
    whileHover={{ scale: 1.05 }}
  >
    <div className="p-6">
      <div className="flex items-center justify-center mb-6">
        <Icon size={40} className="text-teal-600" />
      </div>
      <h3 className="text-xl font-semibold text-gray-800 dark:text-white mb-2 text-center">{title}</h3>
      <p className="text-gray-600 dark:text-gray-300 text-center">{description}</p>
    </div>
    <div className="h-2 bg-gradient-to-r from-teal-400 to-blue-500"></div>
  </motion.div>
);

const Features = () => {
  const features = [
    {
      title: "AI & Machine Learning",
      description:
        "Harness the power of artificial intelligence to drive innovation and efficiency in your business operations.",
      icon: Cpu,
    },
    {
      title: "Cloud Solutions",
      description:
        "Scalable and secure cloud services for enhanced performance, flexibility, and cost-effectiveness.",
      icon: Cloud,
    },
    {
      title: "IT Consulting",
      description:
        "Expert guidance to optimize your IT strategy, infrastructure, and digital transformation initiatives.",
      icon: Users,
    },
    {
      title: "Custom Software",
      description:
        "Tailored software solutions designed to meet your unique business needs and challenges.",
      icon: Zap,
    },
  ];

  return (
    <section id="features" className="bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800 py-20">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-5xl font-bold text-gray-800 dark:text-white mb-4">
            About<span className="text-teal-600 dark:text-teal-400">Us</span>
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Empowering businesses with cutting-edge technology solutions to thrive in the digital era.
          </p>
        </motion.div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <Feature
              key={index}
              title={feature.title}
              description={feature.description}
              icon={feature.icon}
              delay={index * 0.1}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;