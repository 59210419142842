import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';

const services = [
  {
    title: 'Website Development',
    description: 'Custom-built, responsive websites tailored to your brand and business needs.',
    path: '/services/Websitedevelopment',
    icon: '🌐',
  },
  {
    title: 'AI Products',
    description: 'Cutting-edge AI solutions to automate processes and enhance decision-making.',
    path: '/services/ai-products',
    icon: '🤖',
  },
  {
    title: 'Tech Solutions',
    description: 'Comprehensive technological solutions to address complex business challenges.',
    path: '/services/tech-solutions',
    icon: '🔧',
  },
  {
    title: 'IT Services',
    description: 'Managed IT services to keep your infrastructure running smoothly and securely.',
    path: '/services/it-services',
    icon: '💻',
  },
  {
    title: 'Tech Consulting',
    description: 'Expert advice to align your technology strategy with business objectives.',
    path: '/services/tech-consulting',
    icon: '📊',
  },
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 100,
    },
  },
};

const ServiceCard = ({ service }) => {
  const navigate = useNavigate();

  const handleExplore = () => {
    navigate(service.path);
  };

  return (
    <motion.div
      variants={itemVariants}
      whileHover={{ scale: 1.05 }}
      className="bg-gradient-to-br from-teal-700 to-teal-900 rounded-xl shadow-lg p-6 flex flex-col justify-between h-full"
    >
      <div>
        <div className="flex items-center mb-4">
          <div className="p-2 rounded-full bg-teal-800 shadow-md">
            {service.icon}
          </div>
          <h3 className="text-xl font-bold text-white ml-4">{service.title}</h3>
        </div>
        <p className="text-teal-100 mb-6">{service.description}</p>
      </div>
      <button
        onClick={handleExplore}
        className="inline-flex items-center text-white font-semibold hover:text-teal-200 transition-colors duration-300"
      >
        Explore
        <motion.span
          className="ml-2"
          initial={{ x: 0 }}
          whileHover={{ x: 5 }}
          transition={{ type: 'spring', stiffness: 300 }}
        >
          <ArrowRight className="h-5 w-5" />
        </motion.span>
      </button>
    </motion.div>
  );
};

const ServicesComponent = () => {
  return (
    <div className="bg-teal-50 min-h-screen py-16 px-6 md:px-12 overflow-hidden">
      <div className="max-w-6xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: 'easeOut' }}
          className="text-center mb-16"
        >
          <h1 className="text-5xl md:text-6xl font-extrabold text-teal-900 mb-4">
            Innovative Services
          </h1>
          <p className="text-xl text-teal-700">
            Elevate your business with our cutting-edge tech solutions
          </p>
        </motion.div>

        <Routes>
          <Route path="/" element={
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
            >
              {services.map((service, index) => (
                <ServiceCard key={index} service={service} />
              ))}
            </motion.div>
          } />
          {services.map((service, index) => (
            <Route 
              key={index}
              path={service.path} 
              element={<ServiceDetail service={service} />} 
            />
          ))}
        </Routes>
      </div>

      {/* Keep the existing animated background elements */}

      {/* Animated background elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <motion.div
          className="absolute -top-20 -left-20 w-40 h-40 bg-teal-300 rounded-full opacity-20 blur-xl"
          animate={{
            scale: [1, 1.2, 1],
            rotate: [0, 90, 0],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            repeatType: 'reverse',
          }}
        />
        <motion.div
          className="absolute top-1/2 right-0 w-60 h-60 bg-teal-400 rounded-full opacity-20 blur-xl"
          animate={{
            scale: [1, 1.3, 1],
            y: [0, -50, 0],
          }}
          transition={{
            duration: 15,
            repeat: Infinity,
            repeatType: 'reverse',
          }}
        />
        <motion.div
          className="absolute bottom-0 left-1/4 w-80 h-80 bg-teal-500 rounded-full opacity-20 blur-xl"
          animate={{
            scale: [1, 1.4, 1],
            x: [0, 100, 0],
          }}
          transition={{
            duration: 25,
            repeat: Infinity,
            repeatType: 'reverse',
          }}
        />
      </div>
    
    </div>
  );
};

const ServiceDetail = ({ service }) => {
  const navigate = useNavigate();

  const getServiceContent = (title) => {
    switch (title) {
      case 'Website Development':
        return "Our website development process involves understanding your business needs, designing a user-friendly interface, and implementing robust backend systems. We use modern frameworks and best practices to ensure your website is fast, secure, and scalable.";
      case 'AI Products':
        return "We create AI products by leveraging machine learning and deep learning technologies. Our process includes data collection and preprocessing, model selection and training, and deployment of AI solutions that integrate seamlessly with your existing systems.";
      case 'Tech Solutions':
        return "Our tech solutions are tailored to address your specific business challenges. We analyze your requirements, design custom solutions, and implement them using cutting-edge technologies to improve efficiency and drive innovation in your organization.";
      case 'IT Services':
        return "Our IT services cover a wide range of needs, from network management to cybersecurity. We provide proactive monitoring, regular maintenance, and rapid support to ensure your IT infrastructure is always performing at its best.";
      case 'Tech Consulting':
        return "Our tech consulting services help you navigate the complex world of technology. We assess your current systems, identify areas for improvement, and provide strategic recommendations to align your tech strategy with your business goals.";
      default:
        return "Detailed information about this service will be available soon.";
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-8">
      <h2 className="text-3xl font-bold text-teal-900 mb-4">{service.title}</h2>
      <p className="text-teal-700 mb-6">{getServiceContent(service.title)}</p>
      <button
        onClick={() => navigate('/')}
        className="bg-teal-600 text-white px-4 py-2 rounded hover:bg-teal-700 transition-colors duration-300"
      >
        Back to Services
      </button>
    </div>
  );
};

export default ServicesComponent;