import React from 'react';
import { motion } from 'framer-motion';
import Header from './Header';
import Footer from '../Footer';

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  in: { opacity: 1, y: 0 },
  out: { opacity: 0, y: -20 }
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5
};

const WebsiteDevelopmentPage = () => {
  return (
    <>
      <Header />
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
        className="bg-gradient-to-b from-teal-50 to-teal-100 min-h-screen py-32 px-6 md:px-12"
      >
        <div className="max-w-4xl mx-auto">
          <motion.h1 
            className="text-5xl font-bold text-teal-900 mb-6"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Website Development
          </motion.h1>
          <motion.p 
            className="text-xl text-teal-800 mb-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            Transform your online presence with our cutting-edge website development services. We craft stunning, responsive websites that not only captivate your audience but also drive results for your business.
          </motion.p>
          
          <motion.div 
            className="bg-white rounded-lg shadow-lg p-8 mb-12"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.6, duration: 0.8 }}
          >
            <h2 className="text-3xl font-semibold text-teal-900 mb-6">Our Development Process</h2>
            <ol className="space-y-4">
              {[
                "Discovery and Strategy",
                "UI/UX Design",
                "Frontend Development",
                "Backend Integration",
                "Testing and Quality Assurance",
                "Deployment and Maintenance"
              ].map((step, index) => (
                <motion.li 
                  key={index}
                  className="flex items-center text-teal-800"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.8 + index * 0.1, duration: 0.5 }}
                >
                  <span className="mr-4 bg-teal-500 text-white rounded-full w-8 h-8 flex items-center justify-center">{index + 1}</span>
                  {step}
                </motion.li>
              ))}
            </ol>
          </motion.div>
          
          <motion.div
            className="grid md:grid-cols-2 gap-8"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.4, duration: 0.8 }}
          >
            <div className="bg-teal-800 text-white p-6 rounded-lg">
              <h3 className="text-2xl font-semibold mb-4">Technologies We Use</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>React.js</li>
                <li>Node.js</li>
                <li>Next.js</li>
                <li>WordPress</li>
                <li>MongoDB</li>
                <li>AWS / Google Cloud</li>
              </ul>
            </div>
            <div className="bg-teal-700 text-white p-6 rounded-lg">
              <h3 className="text-2xl font-semibold mb-4">Why Choose Us?</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>Expert developers</li>
                <li>Customized solutions</li>
                <li>SEO optimization</li>
                <li>Responsive design</li>
                <li>Ongoing support</li>
                <li>Competitive pricing</li>
              </ul>
            </div>
          </motion.div>
        </div>
      </motion.div>
      <Footer />
    </>
  );
};

export default WebsiteDevelopmentPage;