import React, { useState, useEffect } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import { motion } from 'framer-motion';
import { Calendar, Clock, ChevronRight } from 'lucide-react';

const BlogCard = ({ blog, index }) => (
  <motion.div 
    className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300 overflow-hidden flex flex-col sm:flex-row"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.3, delay: index * 0.1 }}
    whileHover={{ scale: 1.02 }}
  >
    <img
      src={blog.image || 'https://via.placeholder.com/200x200'}
      alt={blog.title}
      className="w-full sm:w-48 h-48 object-cover"
    />
    <div className="flex flex-col justify-between p-4 flex-grow">
      <div>
        <h2 className="text-lg font-semibold text-teal-900 mb-2 line-clamp-2">{blog.title}</h2>
        <p className="text-teal-700 text-sm mb-2 line-clamp-2 hidden sm:block">{blog.description}</p>
      </div>
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-2 sm:mt-0">
        <div className="flex items-center text-xs text-teal-600 mb-2 sm:mb-0">
          <Calendar size={14} className="mr-1" />
          <span>{blog.date}</span>
          <Clock size={14} className="ml-3 mr-1" />
          <span>{blog.readTime} min read</span>
        </div>
        <Link 
          to={`/blog/${blog.id}`} 
          className="inline-flex items-center text-teal-600 hover:text-teal-700 transition-colors duration-300"
        >
          Read More
          <ChevronRight size={16} className="ml-1" />
        </Link>
      </div>
    </div>
  </motion.div>
);

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'blogs'));
        const blogData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          date: new Date(doc.data().createdAt.toDate()).toLocaleDateString(),
          readTime: Math.ceil(doc.data().content.split(' ').length / 200) // Estimate read time
        }));
        setBlogs(blogData);
      } catch (error) {
        console.error('Error fetching blogs: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-teal-600"></div>
      </div>
    );
  }

  return (
    <section className="bg-white py-12 sm:py-16">
      <div className="container mx-auto px-4">
        <motion.h1 
          className="text-3xl sm:text-4xl font-bold text-teal-900 mb-8 sm:mb-10"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Latest <span className="text-teal-600">Insights</span>
        </motion.h1>
        <div className="space-y-6 sm:space-y-8">
          {blogs.map((blog, index) => (
            <BlogCard key={blog.id} blog={blog} index={index} />
          ))}
        </div>
        <motion.div 
          className="mt-8 sm:mt-12 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
        
        </motion.div>
      </div>
    </section>
  );
};

export default BlogList;