import React from 'react';
import { motion } from 'framer-motion';
import Header from './Header';
import Footer from '../Footer';

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  in: { opacity: 1, y: 0 },
  out: { opacity: 0, y: -20 }
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5
};

const AIProductsPage = () => {
  return (
    <>
      <Header />
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
        className="bg-gradient-to-b from-teal-50 to-teal-100 min-h-screen py-32 px-6 md:px-12"
      >
        <div className="max-w-4xl mx-auto">
          <motion.h1 
            className="text-5xl font-bold text-teal-900 mb-6"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            AI Products
          </motion.h1>
          <motion.p 
            className="text-xl text-teal-800 mb-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            Harness the power of artificial intelligence to revolutionize your business. Our AI products are designed to automate processes, enhance decision-making, and unlock valuable insights from your data.
          </motion.p>
          
          <motion.div 
            className="bg-white rounded-lg shadow-lg p-8 mb-12"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.6, duration: 0.8 }}
          >
            <h2 className="text-3xl font-semibold text-teal-900 mb-6">Our AI Solutions</h2>
            <div className="grid md:grid-cols-2 gap-6">
              {[
                { title: "Natural Language Processing", description: "Understand and generate human-like text for chatbots, sentiment analysis, and more." },
                { title: "Computer Vision", description: "Analyze and interpret visual data for object detection, facial recognition, and image classification." },
                { title: "Predictive Analytics", description: "Forecast trends and make data-driven decisions using advanced machine learning algorithms." },
                { title: "Recommendation Systems", description: "Personalize user experiences and increase engagement with AI-powered recommendations." }
              ].map((solution, index) => (
                <motion.div 
                  key={index}
                  className="bg-teal-50 p-4 rounded-lg"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.8 + index * 0.1, duration: 0.5 }}
                >
                  <h3 className="text-xl font-semibold text-teal-900 mb-2">{solution.title}</h3>
                  <p className="text-teal-800">{solution.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
          
          <motion.div
            className="bg-teal-800 text-white p-8 rounded-lg"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.4, duration: 0.8 }}
          >
            <h3 className="text-2xl font-semibold mb-4">Benefits of AI Integration</h3>
            <ul className="grid md:grid-cols-2 gap-4">
              {[
                "Improved efficiency and productivity",
                "Enhanced customer experiences",
                "Data-driven decision making",
                "Automation of repetitive tasks",
                "Increased accuracy and reliability",
                "Scalable solutions for growing businesses"
              ].map((benefit, index) => (
                <motion.li 
                  key={index}
                  className="flex items-center"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1.6 + index * 0.1, duration: 0.5 }}
                >
                  <svg className="w-6 h-6 mr-2 text-teal-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  {benefit}
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </div>
      </motion.div>
      <Footer />
    </>
  );
};

export default AIProductsPage;