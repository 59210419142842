import React from 'react';
import { motion } from 'framer-motion';
import { Zap, Gem, Users, Target } from 'lucide-react';

const FeatureCard = ({ IconComponent, title, description, index }) => (
  <motion.div 
    className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 border border-teal-200"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: index * 0.1 }}
    whileHover={{ scale: 1.05, borderColor: "#0F766E" }}
  >
    <IconComponent className="w-12 h-12 text-teal-600 mb-4" />
    <h3 className="text-xl font-semibold text-teal-900 mb-2">{title}</h3>
    <p className="text-teal-700">{description}</p>
  </motion.div>
);

const WhyChooseUs = () => {
  const features = [
    {
      IconComponent: Zap,
      title: "Innovative Solutions",
      description: "We leverage cutting-edge technologies to deliver forward-thinking solutions for your business challenges."
    },
    {
      IconComponent: Gem,
      title: "Unparalleled Quality",
      description: "Our commitment to excellence ensures that every project meets the highest standards of quality and performance."
    },
    {
      IconComponent: Users,
      title: "Expert Team",
      description: "Our diverse team of skilled professionals brings a wealth of experience and fresh perspectives to every project."
    },
    {
      IconComponent: Target,
      title: "Results-Driven Approach",
      description: "We focus on delivering tangible outcomes that drive your business growth and maximize ROI."
    }
  ];

  return (
    <section className="bg-white to-teal-100 py-20">
      <div className="max-w-6xl mx-auto px-4">
        <motion.div 
          className="text-center mb-16"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-4xl md:text-5xl font-bold text-teal-900 mb-4">
            Why Choose <span className="text-teal-600">Tenchtechtree</span>
          </h2>
          <p className="text-xl text-teal-700 max-w-2xl mx-auto">
            We combine innovation, expertise, and dedication to deliver exceptional tech solutions that propel your business forward.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} index={index} />
          ))}
        </div>

        <motion.div 
          className="mt-16 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <motion.a 
            href="/about" 
            className="bg-teal-600 text-white px-8 py-3 rounded-full font-semibold hover:bg-teal-700 transition-colors duration-300 inline-flex items-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Get Started
            <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

export default WhyChooseUs;