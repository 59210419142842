import React from 'react';
import { motion } from 'framer-motion';
import Header from './Header';
import Footer from '../Footer';

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  in: { opacity: 1, y: 0 },
  out: { opacity: 0, y: -20 }
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5
};

const ServicesPage = () => {
  return (
    <>
      <Header />
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
        className="bg-gradient-to-b from-purple-50 to-purple-100 min-h-screen py-32 px-6 md:px-12"
      >
        <div className="max-w-4xl mx-auto">
          <motion.h1 
            className="text-5xl font-bold text-purple-900 mb-6"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Services
          </motion.h1>
          <motion.p 
            className="text-xl text-purple-800 mb-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            Our comprehensive range of services is designed to support your business at every stage of growth. From development to maintenance, we've got you covered.
          </motion.p>
          
          <motion.div 
            className="bg-white rounded-lg shadow-lg p-8 mb-12"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.6, duration: 0.8 }}
          >
            <h2 className="text-3xl font-semibold text-purple-900 mb-6">Our Service Offerings</h2>
            <div className="grid md:grid-cols-2 gap-6">
              {[
                { title: "Custom Software Development", description: "Tailor-made software solutions to address your unique business challenges." },
                { title: "Cloud Migration & Management", description: "Seamlessly transition and manage your infrastructure in the cloud." },
                { title: "IT Consulting", description: "Expert advice to align your IT strategy with your business goals." },
                { title: "Maintenance & Support", description: "Round-the-clock support to ensure your systems run smoothly." }
              ].map((service, index) => (
                <motion.div 
                  key={index}
                  className="bg-purple-50 p-4 rounded-lg"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.8 + index * 0.1, duration: 0.5 }}
                >
                  <h3 className="text-xl font-semibold text-purple-900 mb-2">{service.title}</h3>
                  <p className="text-purple-800">{service.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
          
          <motion.div
            className="bg-purple-800 text-white p-8 rounded-lg"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.4, duration: 0.8 }}
          >
            <h3 className="text-2xl font-semibold mb-4">Why Our Services Stand Out</h3>
            <ul className="grid md:grid-cols-2 gap-4">
              {[
                "Experienced and certified professionals",
                "Agile methodology for faster delivery",
                "Cutting-edge technologies and best practices",
                "Flexible engagement models",
                "Transparent communication",
                "Commitment to quality and customer satisfaction"
              ].map((feature, index) => (
                <motion.li 
                  key={index}
                  className="flex items-center"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1.6 + index * 0.1, duration: 0.5 }}
                >
                  <svg className="w-6 h-6 mr-2 text-purple-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  {feature}
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </div>
      </motion.div>
      <Footer />
    </>
  );
};

export default ServicesPage;