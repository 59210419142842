import React from 'react';
import { Twitter, Instagram, Facebook, Linkedin, MapPin, Phone, Mail } from 'lucide-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const socialIcons = [
    { Icon: Twitter, url: "https://twitter.com" },
    { Icon: Instagram, url: "https://instagram.com" },
    { Icon: Facebook, url: "https://facebook.com" },
    { Icon: Linkedin, url: "https://linkedin.com" }
  ];

  return (
    <footer className="bg-gradient-to-b from-teal-900 to-teal-950 text-white py-6">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* About Section */}
          <div className="space-y-4 text-center md:text-left">
            <h2 className="text-xl font-bold mb-4 border-b-2 border-teal-500 pb-2">TENCHTECHTRE</h2>
            <p className="text-sm leading-relaxed text-justify">
              At Tenchtechtre, we provide comprehensive IT solutions and services, driven by a passion for innovation and a commitment to excellence. Our services span across research and development, data expertise, website development, and mobile app creation.
            </p>
            <div className="flex justify-center space-x-4 mt-4">
              {socialIcons.map(({ Icon, url }, index) => (
                <a
                  key={index}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-teal-800 p-2 rounded-full hover:bg-teal-600 transition-colors duration-300"
                >
                  <Icon size={18} />
                </a>
              ))}
            </div>
          </div>

          {/* Centered Services Section */}
          <div className="flex flex-col items-center text-center space-y-4">
            <h3 className="text-lg font-semibold mb-4 border-b-2 border-teal-500 pb-2">OUR SERVICES</h3>
            <ul className="space-y-2">
              {['Websites', 'AI Product Based', 'Solutions', 'Services', 'Consulting'].map((service, index) => (
                <li key={index} className="transition-transform duration-300 hover:translate-x-1">
                  <Link to={`/services`} className="hover:text-teal-300 text-sm">
                    {service}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info Section */}
          <div className="space-y-4 text-center md:text-left">
            <h3 className="text-lg font-semibold mb-4 border-b-2 border-teal-500 pb-2">CONTACT INFO</h3>
            <div className="flex flex-col p-3 items-left space-y-4">
              {[
                { Icon: MapPin, text: 'Nagole, Hyderabad' },
                
                { Icon: Mail, text: 'socialmedia@tranchetechtre.com', className: 'lowercase' }
              ].map(({ Icon, text, className }, index) => (
                <div key={index} className="flex items-center space-x-3">
                  <div className="bg-teal-600 p-1.5 rounded-full hover:bg-white transition-colors duration-300">
                    <Icon size={14} className="text-white hover:text-teal-600" />
                  </div>
                  <p className={`text-sm ${className}`}>{text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="border-t border-teal-700 mt-8 pt-4 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <p className="text-xs text-center md:text-left">
            Copyright © {new Date().getFullYear()} Tranchetechtre - All Rights Reserved.
          </p>
          <Link to="/" className="text-xs hover:text-teal-300 transition-colors duration-300">
            Terms & Conditions
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
